/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {useState} from "react";
import { OverlayTrigger, Tooltip  } from "react-bootstrap";
import { useHistory } from "react-router-dom";
import { LazyLoadImage } from "react-lazy-load-image-component";
import 'react-lazy-load-image-component/src/effects/blur.css';
import VideoIframe from "../../../popup/VideoIframe";
import { toAbsoluteUrl } from "../../../../_helpers";
import { FaVideo } from "react-icons/fa";
import { SendInquiry } from "../../../popup/sendInquiry";

export const GridCardContentSimilarTheme2 = (props) => {
    const history = useHistory();
    const [showVideo, setShowVideo] = useState(false);

    function handelDetails(data){
        history.push({  pathname: '/details', state:{
            StockID:data.STOCK_ID,
            diamond_type:data.COLOR?data.diamond_type:data.diamond_type + "F"
        }})
        window.scrollTo(0, 0)
    }


    function certificateLink(){
        if (props.LAB === "IGI"){
            const  certificate_link = `https://www.igi.org/verify-your-report/?r=${props.CERTIFICATE_NO}`
            return certificate_link
        } else if (props.LAB === "GIA"){
            const  certificate_link = `https://www.gia.edu/report-check?reportno=${props.CERTIFICATE_NO}`
            return certificate_link
        } else if (props.LAB === "HRD") {
            const certificate_link = `http://ws2.hrdantwerp.com/HRD.CertificateService.WebAPI/certificate?certificateNumber=${props.CERTIFICATE_NO}`
            return certificate_link
        } else if (props.LAB === "GCAL") {
            const certificate_link = `https://www.gcalusa.com/certificate-search.html?certificate_id=${props.CERTIFICATE_NO}`
            return certificate_link
        } else if (props.CERTIFICATE_NO){
            const certificate_link = props.CERTIFICATE_NO
            return certificate_link
        }
    }
    function handleClose(event, reason) {
        setShowVideo(false)
    }

    function openVideoModal() {
        setShowVideo(true)
        // setOpen(true);
    }
    function Currency(){
        if (props.themeData && props.themeData.api_currency === "USD"){
            const  currencyIcon = "$"
            return currencyIcon
        } else if (props.themeData && props.themeData.api_currency === "INR"){
            const  currencyIcon = "₹"
            return currencyIcon
        } else if (props.themeData && props.themeData.api_currency === "HKD"){
            const  currencyIcon = "$"
            return currencyIcon
        } else if (props.themeData && props.themeData.api_currency === "AUD"){
            const  currencyIcon = "$"
            return currencyIcon
        } else if (props.themeData && props.themeData.api_currency === "EUR"){
            const  currencyIcon = "€"
            return currencyIcon
        } else if (props.themeData && props.themeData.api_currency === "CAD"){
            const  currencyIcon = "$"
            return currencyIcon
        } else if (props.themeData && props.themeData.api_currency === "CNY"){
            const  currencyIcon = "¥"
            return currencyIcon
        } else if (props.themeData && props.themeData.api_currency === "GBP"){
            const  currencyIcon = "£"
            return currencyIcon
        } else if (props.themeData && props.themeData.api_currency === "NZD"){
            const  currencyIcon = "$"
            return currencyIcon
        } else if (props.themeData && props.themeData.api_currency === "JPY"){
            const  currencyIcon = "¥"
            return currencyIcon
        } else if (props.themeData && props.themeData.api_currency === "CHF"){
            const  currencyIcon = "₣"
            return currencyIcon
        }  else if (props.themeData && props.themeData.api_currency === "SGD"){
            const  currencyIcon = "$"
            return currencyIcon
        } else if (props.themeData && props.themeData.api_currency === "THB"){
            const  currencyIcon = "฿"
            return currencyIcon
        } else if (props.themeData && props.themeData.api_currency === "MYR"){
            const  currencyIcon = "RM"
            return currencyIcon
        } else if (props.themeData && props.themeData.api_currency === "BND"){
            const  currencyIcon = "$"
            return currencyIcon
        } else if (props.themeData && props.themeData.api_currency === "DKK"){
            const  currencyIcon = "kr."
            return currencyIcon
        } else if (props.themeData && props.themeData.api_currency === "SEK"){
            const  currencyIcon = "kr"
            return currencyIcon
        }
    }
    function numberWithCommas(x) {
        return x.toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    }
    return (
        <>
            <div
                 className={`col-xl-3 col-lg-4 col-md-6 col-sm-12 mb-5  ${
                    props.diamond_type === "L" ? "labTab" : "natTab"
                }`}
                key={props.CERTIFICATE_NO}
                id={props.CERTIFICATE_NO}
            >
                <div className="row m-0 card gridData">
                    <div className="col-12 p-0 d-flex align-items-center justify-content-center bg-gray-200" style={{height:"280px"}}>
                        {props.IMAGE ? (
                           
                            <LazyLoadImage
                                className="img-fluid cursor-pointer"
                                src={ props.IMAGE}
                                onClick={() => handelDetails(props)}
                                alt={props.SHAPE}
                                onError={e => {
                                    e.currentTarget.src = toAbsoluteUrl(
                                        `/media/shape/${props.SHAPE}-no.webp`
                                        )
                                    }}
                                effect="blur" 
                            />
                        ) : (
                            <img
                                className="img-fluid cursor-pointer"
                                alt="shape"
                                onClick={() => handelDetails(props)}
                                src={toAbsoluteUrl(
                                    `/media/shape/${props.SHAPE}-no.webp`
                                )}
                            />
                            
                        )}
                    </div>
                    <div className="col-12 text-truncate mt-5 mb-3">
                        <OverlayTrigger
                            placement="top"
                            overlay={
                                <Tooltip id="tooltip">
                                    {props.CARAT?parseFloat(props.CARAT).toFixed(2):""} {props.SHAPE}{" "}
                                    {(props.FANCY_COLOR) ? (<>{props.FANCY_INTENSITY} {props.FANCY_OVERTONE} {props.FANCY_COLOR}</>): props.COLOR}{" "}
                                    {props.CLARITY} {props.CUT}{" "}
                                    {props.POLISH} {props.SYMMETRY} {props.FLUORESCENCE}
                                </Tooltip>
                            }
                        >
                            <span className="text-dark font-weight-bold text-capitalize font-size-h5">
                                <span className="pr-2">
                                    {props.CARAT?parseFloat(props.CARAT).toFixed(2):""}
                                </span>
                                <span className="pr-2">{props.SHAPE}</span>
                                { (props.FANCY_COLOR) ?
                                    (
                                        <>
                                             <span className="pr-2">{props.FANCY_INTENSITY}</span>
                                             <span className="pr-2">{props.FANCY_OVERTONE}</span>
                                             <span className="pr-2">{props.FANCY_COLOR}</span>
                                        </>
                                    ) : (
                                        <>
                                             <span className="pr-2">{props.COLOR}</span>
                                        </>
                                    )
                                }
                                <span className="pr-2">{props.CLARITY}</span>
                                <span className="pr-2">{props.CUT}</span>
                                <span className="pr-2">{props.POLISH}</span>
                                <span className="pr-2">{props.SYMMETRY}</span>
                                <span className="pr-2">{props.FLUORESCENCE}</span>
                            </span>
                        </OverlayTrigger>
                    </div>
                    <hr className="w-100 mt-0 mb-0" />
                    <div className="col-12 my-2 d-flex justify-content-between align-items-center">
                        <div>
                            <a
                                id={props.CERTIFICATE_NO}
                                href={certificateLink()}
                                target="_blank"
                                rel="noreferrer"
                                className="font-size-sm text-primary"
                            >
                                {props.LAB}
                            </a>
                           
                            <span className="font-size-sm ml-3 text-primary">
                                {props.VIDEO  ? (
                                    <>
                                        <OverlayTrigger
                                            placement="top"
                                            overlay={
                                                <Tooltip id="tooltip"> Video </Tooltip>
                                            }
                                        >
                                           <span>
                                                <FaVideo className="font-size-sm text-primary cursor-pointer"  onClick={() => openVideoModal(props)} />
                                                <VideoIframe
                                                    showVideo={showVideo}
                                                    handleClose={handleClose}
                                                    src={props.VIDEO }
                                                />
                                           </span>
                                        </OverlayTrigger>
                                    </>
                                ) : (
                                    <span id={props.VIDEO} > </span>
                                )}
                            </span>
                        </div>
                        {props.themeData && !props.themeData.ifm_no_price?<div className="font-size-h5 font-weight-bolder text-primary">{Currency()}{props.TOTAL_PRICE?numberWithCommas(props.TOTAL_PRICE):""}</div>:""}
                    </div>
                    <hr className="w-100 mt-0 mb-3" />
                    <div className="col-12 d-flex align-items-center justify-content-between mb-3">
                        {/* <div className="font-size-h2 font-weight-bolder text-primary">${props.TOTAL_PRICE?parseFloat(props.TOTAL_PRICE).toFixed(2):""}</div> */}
                        <SendInquiry diamond_details={props} ipData={props.ipData} language={props.language}/>
                        <button className="btn btn-sm btn-secondary" onClick={() => handelDetails(props)}>{props.language.CCMODE_THEME1_GRID_DETAILBTN}</button>
                    </div>
                </div>
            </div>
        </>
    );
};
