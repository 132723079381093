/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {useState} from "react";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import { toAbsoluteUrl } from "../../../../_helpers";
import { useHistory } from "react-router-dom";
import { FaVideo } from "react-icons/fa";
import axios from "axios";
import {DETAILPAGE_LOG} from "./../../../../../env_config"
import { LazyLoadImage } from "react-lazy-load-image-component";
import 'react-lazy-load-image-component/src/effects/blur.css';
import VideoIframe from "./VideoIframe";
// import DiamondContext from "../../searchDiamond/SearchDiamondContext";
export const GridCardContent = (props) => {
    const [newprops,setProps] = useState(props)
    // const diamondsearch = useContext(DiamondContext)
    const history = useHistory();

    
    // -------------------------------------------------
    const [showVideo, setShowVideo] = React.useState(false);

    function handleClose(event, reason) {
        setShowVideo(false)
    }

    function openVideoModal() {
        setShowVideo(true)
        // setOpen(true);
    }
    function handelDetails(props){
        history.push({  pathname: '/details', state:newprops})
        const finalData = `{ "JsonData":  [{"ProductId":${props.ProductId}}] ,"CustomerId": 52027, "WLDiamondType": "${props.diamond_type}" }`;
        axios({
            method: "POST",
            url: DETAILPAGE_LOG,
            headers: {
                "Access-Control-Allow-Origin": "*",
                "Content-Type": "application/json",
            },
            data: finalData,
        })
        .then(function(res) {
            // setSuccessOpen(true);
            // setShowWithoutModel(false);
        })
        .catch(function(res) {
        });
        window.scrollTo(0, 0)
    }

    // const  certificate_link =''
    function certificateLink(){
        if (props.LAB === "IGI"){
            const  certificate_link = `https://www.igi.org/verify-your-report/?r=${props.CERTIFICATE_NO}`
            return certificate_link
        } else if (props.LAB === "GIA"){
            const  certificate_link = `https://www.gia.edu/report-check?reportno=${props.CERTIFICATE_NO}`
            return certificate_link
        } else if (props.LAB === "HRD") {
            const certificate_link = `http://ws2.hrdantwerp.com/HRD.CertificateService.WebAPI/certificate?certificateNumber=${props.CERTIFICATE_NO}`
            return certificate_link
        } else if (props.LAB === "GCAL") {
            const certificate_link = `https://www.gcalusa.com/certificate-search.html?certificate_id=${props.CERTIFICATE_NO}`
            return certificate_link
        } else if (props.CERTIFICATE_LINK){
            const certificate_link = props.CERTIFICATE_LINK
            return certificate_link
        }
    }


    return (
        <>
            <div
                className={`col-xl-3 col-lg-4 col-md-6 col-sm-12 mb-5  ${
                    props.diamond_type === "N" ? "natTab" : "labTab"
                }`}
                key={props.ProductId}
                id={props.ProductId}
            >
                <div className="row m-0 card gridData">
                    <div className="col-12 p-0 d-flex align-items-center justify-content-center bg-gray-200" style={{height:"220px"}}>
                        {props.AWS_Image ? (
                            // <img
                            // className="img-fluid"
                            // alt={props.C_Shape}
                            // src={props.AWS_Image}
                            //     // src={toAbsoluteUrl(
                            //     //     `/media/shape/${props.C_Shape}-no.png`
                            //     // )}
                                
                            // />
                            <LazyLoadImage
                                className="img-fluid cursor-pointer"
                                onClick={() => handelDetails(props)}
                                src={ props.AWS_Image}
                                alt={props.C_Shape}
                                onError={e => {
                                    e.currentTarget.src = toAbsoluteUrl(
                                        `/media/shape/${props.C_Shape}-no.webp`
                                        )
                                    }}
                                effect="blur" 
                            />
                        ) : (
                            <img
                                className="img-fluid cursor-pointer"
                                onClick={() => handelDetails(props)}
                                alt="shape"
                                src={toAbsoluteUrl(
                                    `/media/shape/${props.C_Shape}-no.webp`
                                )}
                            />
                            
                        )}
                    </div>
                    <div className="col-12 my-1 d-flex justify-content-between align-items-center">
                        <a
                            id={props.CERTIFICATE_NO}
                            href={certificateLink()}
                            target="_blank"
                            rel="noreferrer"
                            className="font-size-sm text-primary"
                        >
                            {props.LAB}
                        </a>
                       
                        <span className="font-size-sm text-primary">
                            {props.VIDEO ? (
                                <>
                                    <FaVideo className="font-size-sm text-primary cursor-pointer"  onClick={() => openVideoModal(props)} />
                                    <VideoIframe
                                        showVideo={showVideo}
                                        handleClose={handleClose}
                                        src={props.VIDEO }
                                    />
                                </>
                            ) : (
                                <span id={props.VIDEO} > </span>
                            )}
                        </span>
                    </div>
                    <div className="col-12 text-truncate">
                        <OverlayTrigger
                            placement="top"
                            overlay={
                                <Tooltip id="tooltip">
                                    {props.C_Weight && props.C_Weight.toFixed(2)} {props.C_Shape}{" "}
                                    {(props.FANCY_COLOR) ? (<>{props.FANCY_INTENSITY} {props.FANCY_OVERTONE} {props.FANCY_COLOR}</>): props.COLOR}{" "}
                                    {props.C_Clarity} {props.C_Cut}{" "}
                                    {props.C_Polish} {props.C_Symmetry} {props.C_Fluorescence} <br />
                                    M: {props.C_Length && props.C_Length.toFixed(2)} x {props.C_Width && props.C_Width.toFixed(2)} x {props.C_Depth && props.C_Depth.toFixed(2)} T:{props.C_TableP && props.C_TableP.toFixed(2)}%  D:{props.C_DefthP && props.C_DefthP.toFixed(2)}%  R:1.45
                                </Tooltip>
                            }
                        >
                            <span className="text-dark font-weight-bold text-capitalize font-size-h5">
                                <span className="pr-2">
                                    {props.C_Weight.toFixed(2)}
                                </span>
                                <span className="pr-2">{props.C_Shape}</span>
                                { (props.FANCY_COLOR) ?
                                    (
                                        <>
                                             <span className="pr-2">{props.FANCY_COLOR}</span>
                                             <span className="pr-2">{props.FANCY_OVERTONE}</span>
                                             <span className="pr-2">{props.FANCY_COLOR}</span>
                                        </>
                                    ) : (
                                        <>
                                             <span className="pr-2">{props.COLOR}</span>
                                        </>
                                    )
                                }
                                <span className="pr-2">{props.C_Clarity}</span>
                                <span className="pr-2">{props.C_Cut}</span>
                                <span className="pr-2">{props.C_Polish}</span>
                                <span className="pr-2">{props.C_Symmetry}</span>
                                <span className="pr-2">{props.C_Fluorescence}</span>
                            </span>
                            
                        </OverlayTrigger>
                        <hr className="w-75 m-1 px-5 pt-2 pb-0" />
                    </div>

                    <div className="col-12 text-center">
                        <div className="font-size-h1 font-weight-bolder text-primary">
                        <OverlayTrigger
                            placement="top"
                            overlay={
                                <Tooltip id="currency_t">
                                    $/CT {props.MarkUpRate.toLocaleString()}
                                </Tooltip>
                            }
                        >
                           <span> ${props.MarkUpPrice.toLocaleString()}</span>
                        </ OverlayTrigger>
                        </div>
                        <hr className="w-75 m-1 px-5 py-2" />
                    </div>
                    <div className="col-12 d-flex align-items-center justify-content-between mb-5">
                        <button className="btn btn-secondary font-weight-bold" onClick={() => handelDetails(props)}>
                            Details
                        </button>
                    </div>
                </div>
            </div>
           
        </>
    );
};
