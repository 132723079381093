import React, { useState } from "react";
import { BsFillCameraVideoFill } from "react-icons/bs";
import VideoIframe from "./VideoIframe";
export const VideoPopup = (props) => {
    const [show, setShow] = useState(false);

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);
    console.log(props.VIDEO,"props.VIDEO")
    return (
        <>
            <BsFillCameraVideoFill
                className="cursor-pointer text-primary mx-2 text-center"
                onClick={() => handleShow()}
            />

            <VideoIframe
                showVideo={show}
                handleClose={handleClose}
                src={
                    props.Video
                }
            />
        </>
    );
};
